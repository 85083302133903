import React from 'react';
import Layout from '../containers/layout';
import { SizeMe } from 'react-sizeme';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { graphql } from 'gatsby';
import { CvPageQueryQuery } from '../../graphql-types';

// Code to make react-pdf work properly
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  data?: CvPageQueryQuery;
  error: any;
}

const CVPage: React.FC<Props> = ({ data, error }) => {
  const setDynamicWidth = (width: number | null): number => {
    if (!width || width > 1280) {
      return 900;
    } else if (width > 768) {
      return width * 0.7;
    } else {
      return width * 0.9;
    }
  };

  return (
    <SizeMe monitorHeight monitorWidth refreshMode={'debounce'}>
      {({ size }) => (
        <Layout>
          <div className="flex flex-col items-center gap-4 md:items-start md:justify-evenly md:flex-row sm:mt-12 lg:mt-32 overflow-hidden">
            <div className="flex flex-col items-center md:items-start mx-4">
              <h1>CV</h1>
              <div>
                <p className="mt-4 mb-1">
                  For the most updated CV, check out my{' '}
                  <a
                    href="https://linkedin.com/in/oleastole"
                    target="_blank"
                    className="text-primary"
                  >
                    LinkedIn
                  </a>
                </p>
                <p>
                  If you'd like to open the CV in another window, click{' '}
                  <a
                    href={data?.sanityDescriptiveFile?.file?.asset?.url ?? ''}
                    target="_blank"
                    className="text-primary"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>
            <div
              className="sm:mb-8 sm:mr-8"
              style={{
                width: setDynamicWidth(size.width),
              }}
            >
              <Document
                file={data?.sanityDescriptiveFile?.file?.asset?.url ?? ''}
                key={data?.sanityDescriptiveFile?.id}
                onLoadError={console.error}
                onItemClick={({ pageNumber }) =>
                  alert('Clicked an item from page ' + pageNumber + '!')
                }
              >
                <div className="shadow">
                  <Page pageNumber={1} width={setDynamicWidth(size.width)} />
                </div>
              </Document>
            </div>
          </div>
        </Layout>
      )}
    </SizeMe>
  );
};

// TODO: Add a specific key to the CV-file so that you don't need to query on a hardcoded ID
export const query = graphql`
  query CVPageQuery {
    sanityDescriptiveFile(type: { eq: "cv" }) {
      file {
        asset {
          url
          _id
        }
      }
      title
      description
      id
      type
    }
  }
`;

export default CVPage;
